<template>
  <div class="content-wrapper__layout">
    <side-nav></side-nav>
    <div class="content-wrapper__main-content-wrapper content-wrapper__main-content-wrapper--31" :ref="'container'">
      <section class="main-content">
        <div class="section" v-if="total">
          <div class="section__titles">
            <h3 class="section__title">Resultados por categoría</h3>
          </div>
          <div class="tabs" v-if="total.filter(n => n > 0).length > 1">
            <template v-for="(el, i) in allowedViews" :key="i">
              <router-link v-if="total[i] > 0" :class="`tab${filterType == el.route ? ' tab--active' : ''}`" :to="{ name: 'Search', params: { filterType: el.route }}">
                {{el.viewName}}({{total[i]}})
              </router-link>
            </template>
          </div>

          <div class="section__titles">
            <h5 class="section__title">Mostrando resultados para {{filterType}} que contengan "{{keywords}}"</h5>
          </div>
          <!--h5 class="section__title text-alt">Se han encontrado los siguientes ({{total}}) resultados de tipo {{filterType}} para "{{keywords}}"</!--h5-->
          <Loader v-for="index in perPage" :key="index" viewBox="0 0 300 200">
            <rect x="0" y="0" rx="3" ry="3" width="250" height="30" />
            <rect x="20" y="40" rx="3" ry="3" width="260" height="15" />
            <rect x="20" y="70" rx="3" ry="3" width="260" height="15" />
            <rect x="20" y="100" rx="3" ry="3" width="170" height="15" />
          </Loader>
          <template v-if="!loading">
            <div class="list search-result">
              <div class="list__item" v-for="entry in posts" :key="entry.id">
                <div class="item__info">
                  <router-link :to="getRoute(entry)">
                    <p>{{ entry.title }}</p>
                  </router-link>
                  <template v-if="entry.author">
                  <p v-if="entry.route == 'eventos'" class="caption text-alt item__user-info">
                    Por <strong>{{entry.author.fullName}}</strong> planificado para el {{$moment(entry.createdAt).format('LL')}} a las {{$moment(entry.createdAt).format('LT')}}
                  </p>
                  <p v-else class="caption text-alt item__user-info">
                    Por <strong>{{entry.author.fullName}}</strong> el {{$moment.utc(entry.createdAt).local().format('LL')}}
                  </p>
                  </template>
                
                </div>
              </div>
            </div>
          </template>
          <!-- <div class="post-list__end" v-if="this.posts.length == this.totalSelected">
            <img src="@/assets/images/illustrations/ill_checklist.png" alt="En la imagen un listado de tareas completado">
          </div> -->
        </div>
      </section>
      <section class="extra-content">
      </section>
    </div>
    <share-dialog v-if="showShareDialog" :sharing="sharing" @close="showShareDialog = false"></share-dialog>
  </div>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
import { ContentLoader as Loader } from 'vue-content-loader'
import ShareDialog from '@/components/ShareDialog.vue'

export default {
  components: { SideNav, Loader, ShareDialog },
  name: 'Home',
  data(){
    return{
      posts: [],
      loading: true,
      perPage: 15,
      perPageCopy: 15,
      page: 1,
      loadingMorePosts: false,
      total: null,
      totalSelected: null,
      showShareDialog: false,
      sharing: {},
      sortOrder: 'default',
      allowedViews: [
        {route: 'preguntas', apiRoute: 'qna', viewName: 'Preguntas y Publicaciones'}, 
        {route: 'personas', apiRoute: 'people', viewName: 'Personas'},
        {route: 'causas', apiRoute: 'causas', viewName: 'Causas'}, 
        {route: 'areas-de-interes', apiRoute: 'areas', viewName: 'Áreas de interés'}, 
        {route: 'etiquetas', apiRoute: 'tags', viewName: 'Etiquetas'}, 
        {route: 'eventos', apiRoute: 'events', viewName: 'Eventos'}, 
      ],
      currentView: {},
      keywords: this.$route.params.keywords,
      filterType: this.$route.params.filterType,
    }
  },
  async mounted() {
    document.title = `Resultados búsqueda de ${this.$route.params.filterType} que contengan ${this.$route.params.keywords} - Dorapp`
    await this.initialSetup()
    this.$nextTick(() => {
        window.addEventListener('scroll', this.onScroll);
    })
  },
  methods: {
    async initialSetup() {
      this.currentView = this.allowedViews.find(n => n.route == this.filterType)
      
      this.page = 1
      this.total = (await this.$axios.get(`search/total-keyword/${this.currentView.apiRoute}/${this.keywords}`)).data
      this.totalSelected = this.total[this.allowedViews.indexOf(this.currentView)]
      const res = await this.loadPosts()
      this.loading = false
      this.perPage = 0
      this.page = 2
      this.posts = res
    },
    /*async sortEntries() {
      this.perPage = 15
      this.posts.length = 0
      this.loading = true
      this.page = 1
      this.total = (await this.$axios.get(`search/total-keyword/${this.currentView.apiRoute}/${this.keywords}`)).data
      const res = await this.loadPosts()
      this.loading = false
      this.perPage = 0
      this.page = 2
      this.posts = res
    },*/
    async loadPosts() {
      return (await this.$axios.get(`search/results/${this.currentView.apiRoute}/${this.keywords}/${this.page}`)).data
    },
    async onScroll() {
      if(this.$refs.container.getBoundingClientRect().bottom < window.innerHeight + 300 && !this.loadingMorePosts && this.posts.length < this.totalSelected){
        this.loadingMorePosts = true
        this.perPage = this.perPageCopy
        const res = await this.loadPosts()
        this.page = this.page + 1
        this.perPage = 0
        this.posts.push(...res)
        this.loadingMorePosts = false
      }
    },
    getRoute(item){
      if(item.route == 'publicacion')
        return {name: 'Post', params: {id: item.id}}
      if(item.route == 'Profile')
        return {name: 'Profile', params: {id: item.id}}
      if(item.route == 'eventos')
        return {name: 'Event', params: {id: item.id}}
      return {name: 'Filter', params: {filterType: item.route, filterId: item.id}
      }
    }
  },
  beforeUnmount() {
    this.$nextTick(() => {
        window.removeEventListener('scroll', this.onScroll);
    })
  },
  watch:{
    "$route": async function(route) {
      if(route.name == 'Search'){
        this.keywords = this.$route.params.keywords
        this.filterType = this.$route.params.filterType
        document.title = `Resultados búsqueda de ${this.$route.params.filterType} que contengan ${this.$route.params.keywords} - Dorapp`
        this.posts = []
        this.perPage = 10
        this.loading = true
        await this.initialSetup()
      }
    }
  }
}
</script>